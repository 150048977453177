import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Button,
  TablePagination,
  Pagination,
} from "@mui/material";

import "./ListingTable.scss";
import {
  BsCaretDown,
  BsCaretDownFill,
  BsCaretUp,
  BsCaretUpFill,
} from "react-icons/bs";

import { ReactComponent as DownloadSVG } from "../../../assets/icons/download.svg";

// import { ReactComponent as StreetViewSVG } from '../../assets/img/street.svg';
// import { ReactComponent as BlueSVG } from '../../assets/img/blueTable.svg';
// import { ReactComponent as GreenSVG } from '../../assets/img/greenTable.svg';
// import { ReactComponent as GoogleMapSVG } from '../../assets/img/googleMapIcon.svg';
// import { ReactComponent as SpecialStarSVG } from '../../assets/img/specialStar.svg';
// import { ReactComponent as WebSVG } from '../../assets/img/web.svg';
// import { ReactComponent as MailSVG } from '../../assets/img/mail.svg';
import { columns } from "./ListingTable.data";
import { IAddressData } from "../../../types/address.data.interface";
import ButtonThemeOutlined from "../../../components/UI/ButtonThemeOutlined/ButtonThemeOutlined";
import useMediaQuery from "../../../hooks/useMediaQuery";
import usePrintMode from "../../../hooks/usePrintMode";
import Build_location from "../../../assets/icons/zondicons_location.svg";
import PriceComponent from "./PriceComponent";
import MobileRentView from "./MobileRentView";
function getFormattedDate(currDate: any) {
  let date = new Date(currDate);
  let year = date.getFullYear().toString().slice(2, 4);
  let month = (1 + date.getMonth()).toString().padStart(2, "0");
  let day = date.getDate().toString().padStart(2, "0");

  return month + "/" + day + "/" + year;
}

const sortByMostRecent = (data: any) => {
  return data.sort((a: any, b: any) => {
    const dateA = new Date(a.last_seen);
    const dateB = new Date(b.last_seen);

    return dateB.getTime() - dateA.getTime();
  });
};

const getMostRecentItems = (data: any) => {
  const blueItems = data.filter((item: any) => item.flag === "blue");
  const greenItems = data.filter((item: any) => item.flag === "green");

  const mostRecentBlueItems = sortByMostRecent(blueItems).slice(0, 5);
  const remainingSpaces = 5 - mostRecentBlueItems.length;

  const additionalGreenItems = sortByMostRecent(greenItems).slice(
    0,
    remainingSpaces
  );

  return [...mostRecentBlueItems, ...additionalGreenItems];
};

export interface PropertyRow {
  address: string;
  propertyType: string;
  distance: number;
  price: string;
  bedrooms: number;
  bathrooms: string;
  listDate: string;
  size: string;
  priceSqft: string;
}

interface Props {
  data?: IAddressData;
  initialDataCheapest: any[];
  setPanorama: (value: boolean | { lat: number; lng: number }) => void;
  panorama: boolean | { lat: number; lng: number };
  handleTableTypeChange: (type: string) => void;
  tableType: string;
  totalPay?: number;
  avarege?: number;
  proptypeOrigin?: any;
  specialData?: any[];
  credits: number;
}

const ListingTable: React.FC<Props> = ({
  data,
  initialDataCheapest,
  setPanorama,
  panorama,
  handleTableTypeChange,
  tableType,
  totalPay = 0,
  avarege = 0,
  proptypeOrigin,
  credits,
}) => {
  const [dataCheapest, setDataCheapest] = useState(initialDataCheapest);
  const [page, setPage] = useState(1);
  const rowsPerPage = 5;
  const isPrintModeSecond = usePrintMode();

  let rows: PropertyRow[] = [];

  const [isShowAllRows, setIsShowAllRows] = useState(false);

  const [sortConfig, setSortConfig] = useState({ key: "", direction: "asc" });

  const [isPrintMode, setIsPrintMode] = useState(false);

  useEffect(() => {
    const handleBeforePrint = () => {
      setIsPrintMode(true);
      setIsShowAllRows(true);
    };

    const handleAfterPrint = () => {
      setIsPrintMode(false);
      setIsShowAllRows(false);
    };

    window.addEventListener("beforeprint", handleBeforePrint);
    window.addEventListener("afterprint", handleAfterPrint);

    return () => {
      window.removeEventListener("beforeprint", handleBeforePrint);
      window.removeEventListener("afterprint", handleAfterPrint);
    };
  }, []);

  useEffect(() => {
    const sortData = () => {
      const sortedData = [...dataCheapest];
      if (!sortConfig.key) {
        return;
      }

      switch (sortConfig.key) {
        case "distance":
        case "price":
          // Numerical sorting
          sortedData.sort((a, b) => {
            if (sortConfig.direction === "asc") {
              return a[sortConfig.key] - b[sortConfig.key];
            } else {
              return b[sortConfig.key] - a[sortConfig.key];
            }
          });
          break;

        case "size":
          sortedData.sort((a, b) => {
            if (sortConfig.direction === "asc") {
              return a.sqft - b.sqft;
            } else {
              return b.sqft - a.sqft;
            }
          });
          break;

        case "priceSqft":
          sortedData.sort((a, b) => {
            if (sortConfig.direction === "asc") {
              return a.dollar_sqft - b.dollar_sqft;
            } else {
              return b.dollar_sqft - a.dollar_sqft;
            }
          });
          break;

        case "listDate":
          sortedData.sort((a, b) => {
            const dateA = new Date(a.last_seen).getTime();
            const dateB = new Date(b.last_seen).getTime();
            if (sortConfig.direction === "asc") {
              return dateA - dateB;
            } else {
              return dateB - dateA;
            }
          });
          break;

        default:
          // String sorting
          setDataCheapest(initialDataCheapest);
          break;
      }

      setDataCheapest(sortedData);
    };

    sortData();
  }, [sortConfig]);

  useEffect(() => {
    if (dataCheapest && dataCheapest.length === 0) {
      handleTableTypeChange("closest");
    }
  }, []);

  const tableRef = useRef<HTMLTableSectionElement>(null);

  const isMobile = useMediaQuery("(max-width: 768px)");

  if (tableType === "closest") {
    if (data) {
      const lowestPriceProperties = getMostRecentItems(data);
      rows = lowestPriceProperties.map((property): PropertyRow => {
        const {
          address,
          distance,
          price,
          bedrooms,
          baths,
          property_type,
          last_seen,
          sqft,
          dollar_sqft,
        } = property;

        return {
          address,
          propertyType:
            property_type.charAt(0).toUpperCase() + property_type.slice(1) ||
            "Unknown",
          distance,
          price: price
            ? "$" + price.toLocaleString().split(/\s/).join(",")
            : "-",
          bedrooms,
          bathrooms: baths ? baths.replace("ba", "") : "-",
          listDate: last_seen ? getFormattedDate(last_seen) : "-",
          size: sqft ? sqft.toLocaleString().split(/\s/).join(",") : "-",
          priceSqft: dollar_sqft
            ? `$${dollar_sqft.toLocaleString().split(/\s/).join(",")}`
            : "-",
        };
      });
    }
  } else {
    if (dataCheapest) {
      rows = dataCheapest.map((property): PropertyRow => {
        const {
          address,
          distance,
          price,
          bedrooms,
          baths,
          property_type,
          last_seen,
          sqft,
          dollar_sqft,
        } = property;

        return {
          address,
          distance,
          price: String(price),
          size: sqft ? sqft.toLocaleString().split(/\s/).join(",") : "-",
          priceSqft: dollar_sqft
            ? `$${dollar_sqft.toLocaleString().split(/\s/).join(",")}`
            : "-",
          bedrooms,
          bathrooms: baths ? baths.replace("ba", "") : "-",
          propertyType:
            property_type.charAt(0).toUpperCase() + property_type.slice(1) ||
            "Unknown",
          listDate: last_seen ? last_seen : "-",
        };
      });
    }
  }

  const handleChangePage = (event: any, value: any) => {
    setPage(value);
  };

  const paginatedRows = !isShowAllRows
    ? rows.slice((page - 1) * rowsPerPage, page * rowsPerPage)
    : rows;

  const escapeCSVField = (field: string) => {
    if (field.includes('"')) {
      field = field.replace(/"/g, '""');
    }
    if (field.includes(",")) {
      field = `"${field}"`;
    }
    return field;
  };

  const preprocessRows = (rows: PropertyRow[]) => {
    return rows.map((row) => {
      return {
        address: escapeCSVField(row.address),
        distance: row.distance,
        price: escapeCSVField(row.price),
        size: escapeCSVField(row.size),
        priceSqft: escapeCSVField(row.priceSqft),
        bedrooms: row.bedrooms,
        bathrooms: row.bathrooms,
        propertyType: row.propertyType,
        listDate: row.listDate,
      };
    });
  };

  const convertArrayToCSV = (rows: any[]) => {
    const headers = [
      "address",
      "distance (mi)",
      "price",
      "size (sqft)",
      "$/sqft",
      "bedrooms",
      "baths",
      "property type",
      "list date",
    ];

    const data = rows
      .map((row) => {
        return [
          row.address,
          row.distance,
          row.price,
          row.size,
          row.priceSqft,
          row.bedrooms,
          row.bathrooms,
          row.propertyType,
          row.listDate,
        ].join(",");
      })
      .join("\n");

    return `${headers.join(",")}\n${data}`;
  };

  function transformAddressToFileName(address: string) {
    let fileName = address.replace(/,/g, "").replace(/\./g, "");

    fileName = fileName.toLowerCase();
    fileName = fileName.replace(/\s+/g, "-");
    fileName = "Rentometer-enterprise-" + fileName;

    return fileName;
  }

  const downloadCSV = (rows: PropertyRow[]) => {
    const name = transformAddressToFileName(data?.search_address || "");
    const preprocessedRows = preprocessRows(rows);
    const csvContent = convertArrayToCSV(preprocessedRows);

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", name);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleReloadScroll = () => {
    if (tableRef.current) {
      const yOffset = -150;
      const yPosition =
        tableRef.current.getBoundingClientRect().top +
        window.pageYOffset +
        yOffset;
      window.scrollTo({ top: yPosition, behavior: "smooth" });
    } else {
      console.warn("tableRef.current is null");
    }
  };

  const handleTableLength = () => {
    if (isShowAllRows) {
      handleReloadScroll();
      setIsShowAllRows(false);
    } else {
      setIsShowAllRows(true);
    }
  };

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
    };
    return date.toLocaleDateString("en-US", options);
  };

  const sortableColumns = [
    "distance",
    "price",
    "priceSqft",
    "size",
    "listDate",
  ];

  const customHandleSort = (columnId: string, order: string) => {
    if (sortConfig.key === columnId && sortConfig.direction === order) {
      setSortConfig({ key: "", direction: "asc" });
      setDataCheapest(initialDataCheapest);
    } else {
      setSortConfig({ key: columnId, direction: order });
    }
  };

  const getColumnWidth = (columnId: string) => {
    switch (columnId) {
      case "address":
        return isPrintModeSecond ? "auto" : "300px";
      case "distance":
      case "price":
      case "size":
      case "priceSqft":
      case "bedrooms":
      case "bathrooms":
      case "propertyType":
        return isPrintModeSecond ? "auto" : "110px"; // Adjust width as needed
      case "listDate":
        return "auto"; // Adjust width as needed
      default:
        return "auto"; // Default width
    }
  };

  return (
    <>
      <div className="block" />
      <div className="rentComps">
        <div className="rentCompsBox">
          <h4 className="rentCompsBoxTitle">Rent Comps</h4>

          {!isPrintMode && (
            <Button
              variant="contained"
              onClick={() => downloadCSV(rows)}
              style={{
                display: "flex",
                gap: "10px",
                backgroundColor: "#5796D2",
                textTransform: "none",
                padding: "8px 12px 8px 12px",
                fontWeight: 600,
              }}
            >
              <DownloadSVG />
              Download Comps (CSV)
            </Button>
          )}
        </div>

        <div style={{ display: "flex", gap: "40px" }}>
          <div style={{ display: "flex", gap: "10px" }}>
            <p className="secondaryTitle">Rent Comps Found:</p>
            <p className="secondaryTitleNumber">{data?.count}</p>
          </div>
        </div>
      </div>

      {!isMobile && rows && (
        <>
          <TableContainer className="tableContainer" component={Paper}>
            <Table className="table">
              <TableHead className="tableHead" ref={tableRef}>
                <TableRow>
                  {columns.map((column, i) => (
                    <TableCell
                      key={i}
                      className="tableHeadCell"
                      style={{
                        border: "1px solid red",
                        width: getColumnWidth(column.id),
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <div>{column.label}</div>
                        {!isPrintMode &&
                          sortableColumns.includes(column.id) && (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                position: "relative",
                                height: "30px",
                              }}
                            >
                              {sortConfig.key === column.id &&
                              sortConfig.direction === "asc" ? (
                                <BsCaretUpFill
                                  size={16}
                                  style={{
                                    margin: 0,
                                    padding: 0,
                                    lineHeight: 0,
                                    cursor: "pointer",
                                    position: "absolute",
                                    top: "10%",
                                  }}
                                  onClick={() =>
                                    customHandleSort(column.id, "asc")
                                  }
                                />
                              ) : (
                                <BsCaretUp
                                  size={16}
                                  style={{
                                    margin: 0,
                                    padding: 0,
                                    lineHeight: 0,
                                    cursor: "pointer",
                                    position: "absolute",
                                    top: "10%",
                                  }}
                                  onClick={() =>
                                    customHandleSort(column.id, "asc")
                                  }
                                />
                              )}
                              {sortConfig.key === column.id &&
                              sortConfig.direction === "desc" ? (
                                <BsCaretDownFill
                                  style={{
                                    margin: 0,
                                    padding: 0,
                                    lineHeight: 0,
                                    cursor: "pointer",
                                    position: "absolute",
                                    top: "46%",
                                  }}
                                  size={16}
                                  onClick={() =>
                                    customHandleSort(column.id, "desc")
                                  }
                                />
                              ) : (
                                <BsCaretDown
                                  style={{
                                    margin: 0,
                                    padding: 0,
                                    lineHeight: 0,
                                    cursor: "pointer",
                                    position: "absolute",
                                    top: "46%",
                                  }}
                                  size={16}
                                  onClick={() =>
                                    customHandleSort(column.id, "desc")
                                  }
                                />
                              )}
                            </div>
                          )}
                      </div>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedRows.map((row, i) => (
                  <TableRow key={i} className="tableRow">
                    {columns.map((column, j) => {
                      return (
                        <TableCell
                          key={j}
                          className="tableCell"
                          style={{
                            minWidth:
                              j === 0
                                ? isPrintModeSecond
                                  ? ""
                                  : "200px"
                                : "unset",
                          }}
                        >
                          {j === 0 ? (
                            <div className="tableCellContainer">
                              <div className="tableCellContent">
                                <div className="tableCellAddress">
                                  {row[column.id as keyof PropertyRow]}
                                </div>
                                <div className="tableCellIcons"></div>
                              </div>
                            </div>
                          ) : column.id === "size" ? (
                            <div className="sizeContent">
                              {/* Example of square */}
                              {`${
                                row[column.id as keyof PropertyRow]
                              }`.includes("-")
                                ? `${row[column.id as keyof PropertyRow]}`
                                : `${row[column.id as keyof PropertyRow]} ft²`}
                            </div>
                          ) : column.id === "priceSqft" ? (
                            <div className="priceContent">
                              {/* Example of price formatting */}
                              {`${
                                row[column.id as keyof PropertyRow]
                              }`.includes("-")
                                ? `${row[column.id as keyof PropertyRow]}`
                                : `${row[column.id as keyof PropertyRow]}/ft²`}
                            </div>
                          ) : column.id === "price" ? (
                            <div className="distanceContent">
                              {`$${Number(
                                row[column.id as keyof PropertyRow]
                              ).toLocaleString("en-US")}`}
                            </div>
                          ) : column.id === "distance" ? (
                            <div className="distanceContent">
                              {`${row[column.id as keyof PropertyRow]} mi`}
                            </div>
                          ) : column.id === "bedrooms" ? (
                            <div className="bedroomsContent">
                              {`${row[column.id as keyof PropertyRow]} beds`}
                            </div>
                          ) : column.id === "bathrooms" ? (
                            <div className="bathroomsContent">
                              {`${row[column.id as keyof PropertyRow]}ba`}
                            </div>
                          ) : column.id === "listDate" ? (
                            <div className="listDateContent">
                              {formatDate(
                                `${row[column.id as keyof PropertyRow]}`
                              )}
                            </div>
                          ) : (
                            `${row[column.id as keyof PropertyRow]}`
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* <TablePagination
            component="div"
            count={rows.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5]}
          /> */}

          {/* <Box display="flex" gap="24px" alignItems="center" justifyContent="end" m={2}> */}
          <Box
            display="flex"
            flexDirection={isMobile ? "column" : "row"}
            gap={isMobile ? "16px" : "24px"}
            justifyContent={isMobile ? "" : "end"}
            alignItems="center"
            m={2}
          >
            {rows.length > 5 && !isPrintMode ? (
              <>
                <ButtonThemeOutlined
                  content={isShowAllRows ? "Show less" : "Show all"}
                  width={isMobile ? "100%" : "auto"}
                  action={handleTableLength}
                  color="#5796D2"
                  isDisabled={false}
                />
                {/* <Button
                  variant="outlined"
                  style={{ textTransform: "none" }}
                  onClick={handleTableLength}
                >
                  {isShowAllRows ? "Show less" : "Show all"}
                </Button> */}

                {!isShowAllRows && (
                  <Pagination
                    count={Math.ceil(rows.length / rowsPerPage)}
                    page={page}
                    onChange={handleChangePage}
                    variant="outlined"
                    shape="rounded"
                    siblingCount={isMobile ? 0 : 8}
                    sx={{
                      ".MuiPaginationItem-root.Mui-selected": {
                        backgroundColor: "white",
                        border: "1px solid #5796D2",
                        color: "#5796D2",
                        "&:hover": {
                          backgroundColor: "white",
                          border: "1px solid #5796D2",
                          color: "#5796D2",
                        },
                      },
                    }}
                  />
                )}
              </>
            ) : (
              <></>
            )}
          </Box>
        </>
      )}

      {isMobile && <MobileRentView paginatedRows={paginatedRows} />}
      {isMobile && (
        <Box
          display="flex"
          flexDirection={isMobile ? "column" : "row"}
          gap={isMobile ? "16px" : "24px"}
          justifyContent={isMobile ? "" : "end"}
          alignItems="center"
          m={2}
        >
          {rows.length > 5 && !isPrintMode ? (
            <>
              <div style={{ width: "100%" }}>
                <ButtonThemeOutlined
                  content={isShowAllRows ? "Show less" : "Show all"}
                  width={isMobile ? "100%" : "auto"}
                  action={handleTableLength}
                  color="#5796D2"
                  isDisabled={false}
                />
              </div>

              {/* <Button
                  variant="outlined"
                  style={{ textTransform: "none" }}
                  onClick={handleTableLength}
                >
                  {isShowAllRows ? "Show less" : "Show all"}
                </Button> */}

              {!isShowAllRows && (
                <Pagination
                  count={Math.ceil(rows.length / rowsPerPage)}
                  page={page}
                  onChange={handleChangePage}
                  variant="outlined"
                  shape="rounded"
                  siblingCount={isMobile ? 0 : 8}
                  sx={{
                    ".MuiPaginationItem-root.Mui-selected": {
                      backgroundColor: "white",
                      border: "1px solid #5796D2",
                      color: "#5796D2",
                      "&:hover": {
                        backgroundColor: "white",
                        border: "1px solid #5796D2",
                        color: "#5796D2",
                      },
                    },
                  }}
                />
              )}
            </>
          ) : (
            <></>
          )}
        </Box>
      )}
      {/* {isMobile && rows && (
        <Box sx={{ 
                backgroundColor: "#F3F6F9",
                padding: "16px 10px"
              }}>
              Results
            </Box>
        paginatedRows.map((row, index) => (
          <>
            

            <Box key={index} sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              padding: "10px",
              backgroundColor: index % 2 !== 0 ? "#F3F6F9" : "white"
            }}>
              <Box display="flex" alignItems="start" gap="10px">
                {(row.propertyType === "House" || row.propertyType === "Duplex") && <img src={House} alt="building type" />}
                {(row.propertyType === "Apartment" || row.propertyType === "Condo") && <img src={Apartment} alt="building type" />}
                <p style={{
                  fontSize: "16px",
                  fontWeight: 400,
                  lineHeight: "24px",
                  padding: 0,
                  margin: 0,
                  height: "min-content",
                }}>{`${row.propertyType} - ${row.bedrooms}BR/${row.bathrooms}ba (${formatDate(row.listDate)})`}</p>
              </Box>

              <Box display="flex" alignItems="start" gap="10px">
                {row.address && <img src={Build_location} alt="Build_location" />}

                <p style={{
                  fontSize: "16px",
                  fontWeight: 400,
                  lineHeight: "24px",
                  padding: 0,
                  margin: 0,
                  height: "min-content",
                }}>{row.address}</p>
              </Box>
              
              <PriceComponent row={row} />
            </Box>
          </>
        ))
      )} */}
    </>
  );
};

export default ListingTable;
