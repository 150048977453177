import "./EnterpricePage.scss";

import { useEffect, useRef, useState } from "react";

import RentComps from "./containers/RentComps/RentComps";
import { IAddressData, IAddressMap } from "../../types/address.data.interface";
import { UserService } from "../../services/user.service";
import { AddressService } from "../../services/address.service";
import { useParams } from "react-router-dom";

import spiner from "../../assets/icons/orange_spiner.gif";

import ApiRequiredModal from "./components/ApiRequiredModal";
import { RentForm } from "./containers/RentForm/RentForm";
import QuickView from "./containers/QuickView/QuickView";
import Header from "../../components/Header/Header.component";
import Footer from "../../components/Footer/Footer.component";
import useMediaQuery from "../../hooks/useMediaQuery";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage.component";
import Logo from "../../assets/icons/Logo.svg";
import { scroller } from "react-scroll";
import Tabs from "../../components/Tabs/Tabs.component";
import usePrintMode from "../../hooks/usePrintMode";
import { User } from "../../types/user.interface";
import { Box, Typography } from "@mui/material";
import useSessionStorage from "../../hooks/useSessionStorage";
import mobileLogo from "../../assets/icons/logoMobile.svg";
import LogoComponent from "./components/LogoComponent";
import Logogog from '../../assets/Logo.png';
import useBrowserDetection from "../../hooks/useBrowserDetection";
import LogoComponentMobile from "./components/LogoComponentMobile";

const encodeApiKey = (apiKey: string) => {
  try {
    return atob(apiKey);
  } catch (error) {
    return "";
  }
};

const isValidBase64 = (str: string) => {
  const base64Regex =
    /^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$/;
  return base64Regex.test(str);
};

const EnterpricePage = () => {
  const { encodedKey } = useParams();

  const [data, setData] = useState<IAddressMap | null>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isViewLoading, setIsViewLoading] = useState(false);

  const [rentCompsData, setRentCompsData] = useState<IAddressData | null>(null);
  const [rentCompsDataMap, setRentCompsDataMap] = useState<IAddressMap | null>(
    null
  );

  const [isShowQuickView, setIsShowQuickView] = useState(false);
  const [showRentList, setShowRentList] = useState(false);

  const [isEditForm, setIsEditForm] = useState(false);

  // form states
  const [address, setAddress] = useState("");
  const [propertyType, setPropertyType] = useState<string | null>(null);
  const [bedrooms, setBedrooms] = useState<string | null>(null);
  const [baths, setBaths] = useState<string | null>(null);

  const [clearAddress, setClearAddress] = useState(false);

  const [key, setKey] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [fetchErrorMessage, setFetchErrorMessage] = useState("");

  const [initialLoading, setInitialLoading] = useState(true);

  const quickViewRef = useRef<HTMLDivElement>(null);
  const rentCompsRef = useRef<HTMLDivElement>(null);

  const [credits, setCredits] = useState(0);
  const [isCredits, setIsCredits] = useState(false);
  const [quickViewCreditsBalance] = useSessionStorage("quickview_credits");
  const [premiumViewCreditsBalance] = useSessionStorage("premium_credits");

  const [quickViewCredits, setQuickViewCredits] = useState<number | null>(null);
  const [premiumCredits, setPremiumCredits] = useState<number | null>(null);

  const browser = useBrowserDetection();

  const isQuickViewCredits =
    quickViewCreditsBalance !== null ? Number(quickViewCreditsBalance) === 0 : quickViewCredits === 0;
  const isPremiumCredits = 
    premiumViewCreditsBalance !== null ? Number(premiumViewCreditsBalance) === 0 : premiumCredits === 0;

  const sectionRefs = {
    section1: useRef<HTMLDivElement>(null),
    section2: useRef<HTMLDivElement>(null),
    section3: useRef<HTMLDivElement>(null),
  };

  useEffect(() => {
    const getUserInfo = async () => {
      const user: User = await UserService.getUserAuthInfo(key);
      user && setCredits(user.pro_report_credits_remaining);
      user && setQuickViewCredits(user.quickview_credits_remaining);
      user && setPremiumCredits(user.premium_credits_remaining);
      setIsCredits(true);
    };

    key && getUserInfo();
  }, [key]);

  useEffect(() => {
    if (!encodedKey) {
      setErrorMessage("API key is required");
      return;
    }

    if (!isValidBase64(encodedKey)) {
      setErrorMessage("Your key is invalid, please contact support.");
      return;
    }

    const decodedKey = encodeApiKey(encodedKey);
    if (decodedKey) {
      setKey(decodedKey);
      setErrorMessage("");
    } else {
      setErrorMessage("Your key is invalid, please contact support.");
    }
  }, [encodedKey]);

  const handleFetch = async () => {
    setIsLoading(true);
    try {
      const res = await AddressService.getQuickViewData(
        address,
        bedrooms !== null ? bedrooms : "",
        baths !== null ? baths : "",
        propertyType,
        key
      );

      if (res.errors) {
        setFetchErrorMessage(res.errors[0]);
        setData(null);
      } else {
        setData(res);
        setFetchErrorMessage("");
        setIsShowQuickView(true);
        setTimeout(() => {
          handleQuickViewScroll();
        }, 100);
        setQuickViewCredits(prev => prev !== null && prev > 0 ? prev - 1 : 0)
      }
    } catch (error) {
      setFetchErrorMessage("An error occurred while fetching data");
      setData(null);
    } finally {
      setIsLoading(false);
    }
  };

  const handleQuickViewScroll = () => {
    scroller.scrollTo("quickView", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
      offset: -150, // Adjust the offset as needed
    });
  };

  const fetchRentCompsData = async () => {
    const IP = await UserService.getIPAddress();
    const data = await AddressService.getNearbyComps(
      address,
      bedrooms !== null ? bedrooms : "",
      baths !== null ? baths : "",
      propertyType,
      key
    );
    console.log(data);
    setRentCompsData(data as IAddressData);
    setTimeout(() => {
      handleRentCompsScroll();
    }, 100);
    setPremiumCredits(prev => prev !== null && prev > 0 ? prev - 1 : 0);
  };

  const handleRentCompsScroll = () => {
    scroller.scrollTo("rentComps", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
      offset: -150,
    });
  };

  const fetchRentCompsDataMap = async () => {
    const IP = await UserService.getIPAddress();
    const dataMap = await AddressService.getAddressDataForMap(
      address,
      bedrooms !== null ? bedrooms : "",
      baths !== null ? baths : "",
      propertyType,
      IP.ip
    );
    //setRentCompsDataMap(dataMap as IAddressMap);
  };

  const handleViewListOfRents = async () => {
    setIsViewLoading(true);
    await fetchRentCompsData();
    // await fetchRentCompsDataMap();
    setShowRentList(true);
    setIsViewLoading(false);
  };

  const handleEditForm = () => {
    setIsEditForm(true);
    setShowRentList(false);
  };

  const handleReset = () => {
    setIsShowQuickView(false);
    setShowRentList(false);
    setClearAddress(true);
    setPropertyType(null);
    setBedrooms(null);
    setBaths(null);
    setFetchErrorMessage("");
  };

  const isMobile = useMediaQuery("(max-width: 768px)");
  const isPrintMode = usePrintMode();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        justifyContent: "space-between",
        paddingBottom: "50px",
        gap: "40px",
      }}
    >
      <div className="enterpricePage">
        <div className="none">
          <Header />
        </div>

        {!key && errorMessage && (
          <ApiRequiredModal errorMessage={errorMessage} />
        )}
        <div
          className="enterpricePageRentLogo"
          style={
            {
              // display: "flex",
              // alignItems: "center",
              // justifyContent: "center",
              // marginBottom: "40px",
              // paddingTop: "100px",
            }
          }
        >
          {isMobile ? <LogoComponentMobile /> : isPrintMode ?  <img src={Logogog} alt="" width={503} height={60} /> : <LogoComponent />}
          {/* <img src={Logogog} alt="" width={503} height={60} /> */}
        </div>

        <div className="formContainer main-container">
          <div id="section1" ref={sectionRefs.section1}>
            <div className="">
              <RentForm
                handleFetch={handleFetch}
                handleReset={handleReset}
                isQuickView={isShowQuickView || !!fetchErrorMessage}
                address={address}
                setAddress={setAddress}
                bedrooms={bedrooms}
                setBedrooms={setBedrooms}
                baths={baths}
                setBaths={setBaths}
                propertyType={propertyType}
                setPropertyType={setPropertyType}
                clearAddress={clearAddress}
                setClearAddress={setClearAddress}
                isEditForm={isEditForm}
                setIsEditForm={setIsEditForm}
                setShowRentList={setShowRentList}
                credits={credits}
                isZeroCredits={isQuickViewCredits}
              />
            </div>
          </div>

          <div id="section2" ref={sectionRefs.section2} className="">
            {isShowQuickView && data !== null && !fetchErrorMessage && (
              <div
                id="quickView"
                ref={quickViewRef}
                className={browser === "chrome" ? "page-break-land" : ""}
              >
                <QuickView
                  data={data}
                  bedrooms={bedrooms}
                  handleViewListOfRents={handleViewListOfRents}
                  showRentList={showRentList}
                  isEditForm={isEditForm}
                  propertyType={propertyType}
                  baths={baths}
                  isZeroCredits={isPremiumCredits}
                />
              </div>
            )}

            {fetchErrorMessage && (
              <div style={{ marginBottom: "50px" }}>
                <ErrorMessage />
              </div>
            )}
          </div>

          <div id="section3" ref={sectionRefs.section3} className="22">
            {showRentList && rentCompsData !== null && data !== null && (
              <div
                id="rentComps"
                ref={rentCompsRef}
                className={`${browser === 'safari' ? 'rentCompsContainer page-break  page-break-port' : 'page-break-port'}`}
              >
                <RentComps
                  rentCompsData={rentCompsData}
                  rentCompsDataMap={data}
                  rentCompsRef={rentCompsRef}
                />
              </div>
            )}
          </div>

          {(isLoading || isViewLoading) && !isEditForm ? (
            <div className="spinner" style={{ padding: "100px 0" }}>
              <img src={spiner} alt="Loading..."></img>
            </div>
          ) : null}
        </div>

        {/* <div
          style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "13px" }}
        >
          {quickViewCredits === 0 && premiumCredits === 0 && isCredits && !isPrintMode && (
            <Box
              className={`${
                isShowQuickView? "zeroBalanceBox2" : "zeroBalanceBox1"
              } main-container`}
              sx={{ width: "100%", padding: "0px" }}
            >
              <Box className="creditsAlert">
                <img src={creditsAlertIcon} alt="" />

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography className="creditsAlertText">
                    Your QuickView™ Rent Estimate credit balance is 0. Please contact for assistance {" "}
                    <a
                      target="_blank"
                      href="https://www.rentometer.com/rentometer-api/settings"
                      style={{ textDecoration: "none" }}
                    >
                      <span
                        style={{
                          color: "#E91010",
                          fontSize: "18px",
                          lineHeight: "32px",
                          fontWeight: 700,
                          textDecoration: "underline",
                        }}
                      >
                        support@rentometer.com{" "}
                      </span>
                        {" "}for assistance.
                    </a>
                  </Typography>
                </div>
              </Box>
            </Box>
          )}
        </div> */}
      </div>

      <div style={{ borderTop: "1px solid #F3F6F9" }} className="none">
        <Footer />
      </div>

      {isMobile && !isPrintMode && (
        <Tabs
          sectionRefs={sectionRefs}
          isShowQuickView={isShowQuickView}
          showRentList={showRentList}
        />
      )}

      {/* <StaticSections /> */}
    </div>
  );
};

export default EnterpricePage;
