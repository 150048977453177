import { useMemo, FC } from "react";
import { IAddressMap } from "../../../../types/address.data.interface";
// import { ReactComponent as MarkerSVG } from '../../../assets/icons/Meter.svg';
import RentMeterSVG from "../RentMeterSVG";
import { ReactComponent as Arrow } from '../../../../assets/icons/rent-arrow.svg';

import './RentMeter.scss';

type Props = {
  rentCompsDataMap: IAddressMap;
};

const RentMeter: FC<Props> = (props) => {
  const calculatePercentile = (min: number, max: number, percentage: number) => {
    return (max - min) * percentage + min;
  };

  function calculateAngle(percentage: number) {
    const minAngle = -13; // Angle for 0%
    const maxAngle = 195; // Angle for 100%
    const midAngle = 90; // Angle for 50%
  
    // Calculate the angle based on the percentage using a linear interpolation
    if (percentage <= 50) {
      return minAngle + (midAngle - minAngle) * (percentage / 50);
    } else {
      return midAngle + (maxAngle - midAngle) * ((percentage - 50) / 50);
    }
  }

  const memoizedValues = useMemo(() => {
    let arrowToMin = false;
    let arrowToMax = false;
    let resultDescription: React.ReactNode = "";
    let resultTitle: React.ReactNode = "";
    //let yourRentPrice = totalPay;
    let primary_percentile_05 =
      props.rentCompsDataMap.mean - 1.645 * props.rentCompsDataMap.std_dev;
    let primary_percentile_95 =
      props.rentCompsDataMap.mean + 1.645 * props.rentCompsDataMap.std_dev;

    let percentile_05 = calculatePercentile(
      primary_percentile_05,
      primary_percentile_95,
      0.05
    );
    let percentile_25 = calculatePercentile(
      primary_percentile_05,
      primary_percentile_95,
      0.25
    );
    let percentile_75 = calculatePercentile(
      primary_percentile_05,
      primary_percentile_95,
      0.75
    );
    let percentile_95 = calculatePercentile(
      primary_percentile_05,
      primary_percentile_95,
      0.95
    );
   
    return {
      //arrowAngleValue,
      resultDescription,
      resultTitle,
      //rentPercentage,
      percentile_05,
      percentile_25,
      percentile_75,
      percentile_95,
    };
  }, [props.rentCompsDataMap]);

  return <><div className="resultMainMeter2">
  <div className="indicator">
    <div className="indicator-box">
      <span className="min">
        ${Math.round(memoizedValues.percentile_05)?.toLocaleString()}
      </span>
      <span className="max">
        ${Math.round(memoizedValues.percentile_95)?.toLocaleString()}
      </span>
      <span className="percentile-25">
        ${Math.round(memoizedValues.percentile_25)?.toLocaleString()}
      </span>
      <span className="percentile-75">
        ${Math.round(memoizedValues.percentile_75)?.toLocaleString()}
      </span>
      {/* <span className="average-rent">
          ${'150'.toLocaleString()}
        </span> */}
      <span
        className="your-rent green"
        style={{
          left: 100 <= 50 ? "-20px" : "auto",
          right: 100 <= 50 ? "auto" : "-20px",
        }}
      >
        {/* ${'750'?.toLocaleString()} */}
      </span>
      <span
          className="rent-arrow"
          style={{
            transform: `${
              props.rentCompsDataMap.mean
                ? 'rotate(90deg)'
                : 'rotate(0deg)'
            }`
          }}>
          <Arrow />
          {/* <div
            className="rent-line"
            style={{ display: `${true ? 'block' : 'none'}` }}></div> */}
        </span>
      <RentMeterSVG
        // className={
        //   true
        //     ? 24 <= 25
        //       ? "animated-pink"
        //       : 100 >= 75
        //       ? "animated-green"
        //       : ""
        //     : ""
       // }
      />
    </div>
    {/* <div className="indicator-colors">
        <div className="indicator-color-title">
          <h5 className="indicator-color-budget">Your Budget</h5>
          <div className="indicator-color-desc">
            For this area, your budget is
          </div>
        </div>
        <div className="indicator-color">
          <div className="color-box"></div>
          Too low
        </div>
        <div className="indicator-color">
          <div className="color-box"></div>
          Reasonable
        </div>
        <div className="indicator-color">
          <div className="color-box"></div>
          Great
        </div>
      </div> */}
  </div>
  {/* <div className="resultSummaryData">
      Results based on {fetchResponse.samples}, {fetchResponse.bedrooms}-bedroom
      rentals seen within
      <br /> 12 months within a {fetchResponse.radius_miles} mile radius
    </div> */}
</div></>;
};

export default RentMeter;
