import CustomGoogleAutocomplete from "../../../../components/Autocomplete/CustomGoogleAutocomplete.component";
import "./RentForm.scss";
import TitleBox from "./components/TitleBox";
import FormButton from "./components/FormButton";
import CustomRadioElement from "../../../../components/CustomRadioInput/CustomRadioInput";
import { Box, Button, RadioGroup, Tooltip, Typography } from "@mui/material";
import ButtonThemeContained from "../../../../components/UI/ButtonThemeContained/ButtonThemeContained";
import ButtonThemeOutlined from "../../../../components/UI/ButtonThemeOutlined/ButtonThemeOutlined";
import { useState } from "react";
import useMediaQuery from "../../../../hooks/useMediaQuery";
import BedroomsIcon from "../../../../assets/icons/bedroom.svg";
import BathroomsIcon from "../../../../assets/icons/baths.svg";

import ResetIcon from "../../../../assets/icons/reset.svg";
import ResetIconDisabled from "../../../../assets/icons/resetDisabled.svg";
import usePrintMode from "../../../../hooks/usePrintMode";
import ErrorContainer from "../../components/ErrorContainer";

export const LABELS = {
  propType: ["Houses/SFR", "Apt/Condo", "All Types"],
  beds: [1, 2, 3, 4],
  baths: ["1", "1+", "Any"],
};

export const RentForm = ({
  handleFetch,
  handleReset,
  isQuickView,
  address,
  setAddress,
  bedrooms,
  setBedrooms,
  baths,
  setBaths,
  propertyType,
  setPropertyType,
  clearAddress,
  setClearAddress,
  isEditForm,
  setIsEditForm,
  setShowRentList,
  credits,
  isZeroCredits,
}: {
  handleFetch: () => Promise<void>;
  isQuickView: boolean;
  handleReset: () => void;
  address: string;
  setAddress: React.Dispatch<React.SetStateAction<string>>;
  bedrooms: string | null;
  setBedrooms: React.Dispatch<React.SetStateAction<string | null>>;
  baths: string | null;
  setBaths: React.Dispatch<React.SetStateAction<string | null>>;
  propertyType: string | null;
  setPropertyType: React.Dispatch<React.SetStateAction<string | null>>;
  clearAddress: boolean;
  setClearAddress: React.Dispatch<React.SetStateAction<boolean>>;
  isEditForm: boolean;
  setIsEditForm: React.Dispatch<React.SetStateAction<boolean>>;
  setShowRentList: React.Dispatch<React.SetStateAction<boolean>>;
  credits: number;
  isZeroCredits: boolean;
}) => {
  // hadle function to change initial form states
  const handleBedrooms = (event: any) => {
    setBedrooms(event.target.value);
  };

  const handleBaths = (event: any) => {
    setBaths(event.target.value);
  };

  const handlePropertyType = (event: any) => {
    setPropertyType(event.target.value);
  };

  const handleEditForm = () => {
    setIsEditForm(true);
    setShowRentList(false);
  };

  const handleFetchData = async () => {
    await handleFetch();
    setIsEditForm(false);
  };

  const isMobile = useMediaQuery("(max-width: 768px)");
  const isPrintMode = usePrintMode();

  return (
    <>
      <div className="formContent">
        <div style={{ display: "flex", flexDirection: "column", gap: "40px" }}>
          <TitleBox />

          <div>
            <div style={{ marginBottom: !isPrintMode ? "40px" : "10px" }}>
              <CustomGoogleAutocomplete
                setAddress={setAddress}
                clearAddress={clearAddress}
                setClearAddress={setClearAddress}
                isDisabled={isQuickView || isZeroCredits}
              />
            </div>

            {/* <div style={{ display: "flex", justifyContent: "space-between" }}> */}
            <div className="properties-section">
              {/*property type box*/}
              <div className="sectionType">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "14px",
                    gap: "6px",
                  }}
                >
                  <p className="title">Property Type</p>
                </div>
                <RadioGroup
                  aria-label="propertyType"
                  name="propertyType"
                  value={propertyType}
                  onChange={handlePropertyType}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    //gap: "16px"
                  }}
                >
                  <CustomRadioElement
                    value="house"
                    selectedValue={propertyType as string}
                    label="Houses/SFH"
                    onChange={handlePropertyType}
                    isDisabled={isQuickView || isZeroCredits}
                  />
                  <CustomRadioElement
                    value="apartment"
                    selectedValue={propertyType as string}
                    label="Apt/Condo"
                    onChange={handlePropertyType}
                    isDisabled={isQuickView || isZeroCredits}
                  />
                  <CustomRadioElement
                    value=""
                    selectedValue={propertyType as string}
                    label="All Types"
                    onChange={handlePropertyType}
                    isDisabled={isQuickView || isZeroCredits}
                  />
                </RadioGroup>
              </div>

              <div className="sectionType">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "14px",
                    gap: "6px",
                  }}
                >
                  <img src={BedroomsIcon} alt="bedrooms icon" />
                  <p className="title">Number of bedrooms</p>
                </div>

                <RadioGroup
                  aria-label="bedrooms"
                  name="bedrooms"
                  value="1"
                  onChange={handleBedrooms}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "space-between",
                    gap: "10px",
                    //gap: "16px"
                  }}
                >
                  <CustomRadioElement
                    value="0"
                    selectedValue={bedrooms as string}
                    label="Studio"
                    onChange={handleBedrooms}
                    minWidth={isMobile ? "100%" : "80px"}
                    isDisabled={(isQuickView && !isEditForm) || isZeroCredits}
                  />
                  <CustomRadioElement
                    value="1"
                    selectedValue={bedrooms as string}
                    label="1"
                    onChange={handleBedrooms}
                    minWidth="50px"
                    isDisabled={(isQuickView && !isEditForm) || isZeroCredits}
                  />
                  <CustomRadioElement
                    value="2"
                    selectedValue={bedrooms as string}
                    label="2"
                    onChange={handleBedrooms}
                    minWidth="50px"
                    isDisabled={(isQuickView && !isEditForm) || isZeroCredits}
                  />
                  <CustomRadioElement
                    value="3"
                    selectedValue={bedrooms as string}
                    label="3"
                    onChange={handleBedrooms}
                    minWidth="50px"
                    isDisabled={(isQuickView && !isEditForm) || isZeroCredits}
                  />
                  <CustomRadioElement
                    value="4"
                    selectedValue={bedrooms as string}
                    label="4"
                    onChange={handleBedrooms}
                    minWidth="50px"
                    isDisabled={(isQuickView && !isEditForm) || isZeroCredits}
                  />
                </RadioGroup>
              </div>

              <div className="sectionType">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "14px",
                    gap: "6px",
                  }}
                >
                  <img src={BathroomsIcon} alt="bedrooms icon" />
                  <p className="title">Number of bathrooms (optional)</p>
                </div>

                <RadioGroup
                  aria-label="bathroomType"
                  name="bathroomType"
                  value={baths}
                  onChange={handleBaths}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    //gap: "16px"
                  }}
                >
                  <CustomRadioElement
                    value="1"
                    selectedValue={baths as string}
                    label="1"
                    onChange={handleBaths}
                    minWidth="50px"
                    isDisabled={(isQuickView && !isEditForm) || isZeroCredits}
                  />
                  <CustomRadioElement
                    value="1.5"
                    selectedValue={baths as string}
                    label="1+"
                    onChange={handleBaths}
                    minWidth="50px"
                    isDisabled={(isQuickView && !isEditForm) || isZeroCredits}
                  />
                  <CustomRadioElement
                    value=""
                    selectedValue={baths as string}
                    label="All"
                    onChange={handleBaths}
                    minWidth="50px"
                    isDisabled={(isQuickView && !isEditForm) || isZeroCredits}
                  />
                </RadioGroup>
              </div>
            </div>
          </div>

          {/* <FormButton
            onFetch={handleFetch}
            onReset={handleReset}
            isDisabled={
              !address || propertyType === null || baths === null || !bedrooms
            }
            isShowResetButton={isQuickView}
          /> */}

          {!isPrintMode && (
            <Box display="flex" flexDirection="column" gap="20px">
              <Box display="flex" justifyContent="center">
                {!isQuickView ? (
                  <Box
                    display="flex"
                    gap="12px"
                    width="100%"
                    justifyContent="center"
                  >
                    <div style={{ flex: isMobile ? 1 : "none" }}>
                      <ButtonThemeContained
                        content="Check rents"
                        width={isMobile ? "100%" : "154px"}
                        action={handleFetchData}
                        isDisabled={
                          !address ||
                          propertyType === null ||
                          !bedrooms ||
                          isZeroCredits
                        }
                        isZeroCredits={isZeroCredits}
                        tooltipContent="You need QuickView™ Rent Estimate credits to activate this button."
                      />
                    </div>
                  </Box>
                ) : isEditForm ? (
                  <Box
                    display="flex"
                    gap="12px"
                    width="100%"
                    justifyContent="center"
                  >
                    <div style={{ flex: isMobile ? 1 : "none" }}>
                      <ButtonThemeOutlined
                        content="New Search"
                        width={isMobile ? "100%" : "154px"}
                        action={handleReset}
                        icon={isZeroCredits ? ResetIconDisabled : ResetIcon}
                        isDisabled={isZeroCredits}
                        isZeroCredits={isZeroCredits}
                        tooltipContent="You need QuickView™ Rent Estimate credits to activate this button."
                      />
                    </div>

                    <div style={{ flex: isMobile ? 1 : "none" }}>
                      <ButtonThemeContained
                        content="Check rents"
                        width={isMobile ? "100%" : "154px"}
                        action={handleFetchData}
                        isZeroCredits={isZeroCredits}
                        isDisabled={
                          !address ||
                          propertyType === null ||
                          !bedrooms ||
                          isZeroCredits
                        }
                        tooltipContent="You need QuickView™ Rent Estimate credits to activate this button."
                      />
                    </div>
                  </Box>
                ) : (
                  <Box
                    display="flex"
                    gap="12px"
                    width="100%"
                    justifyContent="center"
                  >
                    <div style={{ flex: isMobile ? 1 : "none" }}>
                      <ButtonThemeOutlined
                        content="New Search"
                        width={isMobile ? "100%" : "154px"}
                        action={handleReset}
                        icon={isZeroCredits ? ResetIconDisabled : ResetIcon}
                        isDisabled={isZeroCredits}
                        isZeroCredits={isZeroCredits}
                        tooltipContent="You need QuickView™ Rent Estimate credits to activate this button."
                      />
                    </div>

                    <div style={{ flex: isMobile ? 1 : "none" }}>
                      <ButtonThemeContained
                        content="Edit Br/Ba"
                        width={isMobile ? "100%" : "154px"}
                        action={handleEditForm}
                        isDisabled={isZeroCredits}
                        isZeroCredits={isZeroCredits}
                        tooltipContent="You need QuickView™ Rent Estimate credits to activate this button."
                      />
                    </div>
                  </Box>
                )}
              </Box>
              {isZeroCredits && !isPrintMode && (
                <ErrorContainer creditsType="premium_credits" />
              )}
            </Box>
          )}
        </div>
      </div>
    </>
  );
};
