import styled from "@emotion/styled";
import { Button, Tooltip } from "@mui/material";

const CustomButton = styled(Button)<{ buttonColor: string }>(({ buttonColor }) => ({
  display: "flex",
  justifyContent: "center",
  gap: "10px",
  alignItems: "center",
  padding: "8px 12px",
  //backgroundColor: "#EF6F22",
  border: `2px solid ${buttonColor}`,
  borderRadius: "4px",
  color: buttonColor,
  fontSize: "14px",
  fontWeight: "600",
  lineHeight: "25px",
  textTransform: "none",
  "&:hover": {
    //backgroundColor: "#F28C4E",
    border: `2px solid ${buttonColor}`,
    borderRadius: "4px",
    backgroundColor: 'white',
    //color: "white",
    //backgroundColor: "#F28C4E",
  },
  "&.Mui-disabled": {
    border: `2px solid #B3BCC9`,
    borderRadius: "4px",
    backgroundColor: '',
    color: "#717780",
  },
}));

type Props = {
  content: string;
  width: string;
  action: () => Promise<void> | void;
  icon?: string;
  color?: string;
  isDisabled: boolean;
  isZeroCredits?: boolean;
  tooltipContent?: string;
};

const ButtonThemeOutlined = ({ content, width, action, icon, color, isDisabled, isZeroCredits, tooltipContent }: Props) => {
  return (
    <Tooltip title={isZeroCredits ? tooltipContent : ""}>
      <span>
      <CustomButton 
      variant="outlined" 
      sx={{ width }} 
      onClick={action}
      buttonColor={color ? color : "#EF6F22"}
      disabled={isDisabled}
    >
      {icon && <img src={icon} alt="" />}
      {content}
    </CustomButton>
      </span>
    </Tooltip>
   
  );
};

export default ButtonThemeOutlined;
