import { IAddressMap } from "../../../../types/address.data.interface";
import { ReactComponent as LocationSVG } from "../../../../assets/icons/location.svg";
import { Box, Button, Tooltip } from "@mui/material";
import RentMeter from "../../components/RentMeter/RentMeter";
import ResultContainer from "../../../../components/ResultContainer/ResultContainer";
import styled from "@emotion/styled";

import "./QuickView.scss";
import useMediaQuery from "../../../../hooks/useMediaQuery";
import usePrintMode from "../../../../hooks/usePrintMode";
import ErrorContainer from "../../components/ErrorContainer";

const GridContainer = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(4, 1fr);

  @media (max-width: 1100px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const GridItem = styled.div`
  text-align: center; // Example styling
`;

const CustomButton = styled(Button)(({ theme }) => ({
  padding: "8px 60px",
  backgroundColor: "#196FAF",
  color: "white",
  fontSize: "14px",
  fontWeight: "600",
  lineHeight: "25px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#115293",
  },
  "&.Mui-disabled": {
    backgroundColor: "#ABCAE8",
    color: "white",
  },
}));

type Props = {
  data: IAddressMap;
  bedrooms: string | null;
  handleViewListOfRents: () => Promise<void>;
  showRentList: boolean;
  isEditForm: boolean;
  propertyType: string | null;
  baths: string | null;
  isZeroCredits: boolean;
};

const QuickView = ({
  data,
  bedrooms,
  handleViewListOfRents,
  showRentList,
  isEditForm,
  propertyType,
  baths,
  isZeroCredits,
}: Props) => {
  const values = [
    { label: "average", name: "AVERAGE", price: data?.mean || 0 },
    { label: "median", name: "MEDIAN", price: data?.median || 0 },
    { label: "25th", name: "25th %", price: data?.percentile_25 || 0 },
    { label: "75th", name: "75th %", price: data?.percentile_75 || 0 },
  ];

  const isMobile = useMediaQuery("(max-width: 767px)");
  const isNotDesktop = useMediaQuery("(max-width: 1100px)");
  const isPrintMode = usePrintMode();

  const formatDate = (date: Date): string => {
    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "long",
      year: "numeric",
    };
    return new Intl.DateTimeFormat("en-GB", options).format(date);
  };

  const currentDate = formatDate(new Date());

  return (
    <div className="viewContainer">
      <div className="block" />

      <Box>
        <div className="content">
          <div className="info">
            <div className="infoTitleBox">
              <h5 className="title trademark-block titleShort">
                QuickView<span className="trademark">TM</span>
              </h5>
              <h5 className="title">Rent Estimate</h5>
            </div>

            <div className="addressBlock">
              <LocationSVG />

              <h6 className="address">{data.address}</h6>
            </div>

            <h6 className="description">
              Results based on {data?.samples},{" "}
              {data.bedrooms && data.bedrooms > 0
                ? `${data.bedrooms}-bedroom`
                : `studio`}
              , {data.building_type === "Apartment" && "Apartment "}
              {data.building_type === "House" && "House "}
              {data.baths === "1 only" && "single bath "}
              {data.baths === "1.5+" && "1½ or more bath "}
              rentals seen within{" "}
              {data?.look_back_days
                ? Math.floor(data?.look_back_days / 30)
                : 0}{" "}
              months in a {data?.radius_miles} mile radius
            </h6>

            {!isPrintMode ? (
              <div
                className="buttonBox"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <Tooltip
                  title={
                    isZeroCredits
                      ? "You need Rent Comp credits to activate this button."
                      : ""
                  }
                >
                  <span>
                    <CustomButton
                      variant="contained"
                      //className="viewBtn"
                      onClick={() => handleViewListOfRents()}
                      disabled={showRentList || isEditForm || isZeroCredits}
                      // style={{ padding: "15px 60px" }}
                      style={{
                        width: isMobile ? "100%" : "auto",
                      }}
                    >
                      View Comps
                    </CustomButton>
                  </span>
                </Tooltip>
              </div>
            ) : (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <p className="analysis">Analysis generated on {currentDate}</p>
              </div>
            )}
          </div>

          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
            className="metrics"
          >
            <div className="meterBox">
              {data !== null && <RentMeter rentCompsDataMap={data} />}
            </div>

            <GridContainer
              style={{
                width: isNotDesktop || isPrintMode ? "100%" : "auto",
                marginBottom: isMobile ? "80px" : "",
              }}
              className="result-grid"
            >
              {values.map((item) => (
                <GridItem key={item.label} className="result-grid-item">
                  <ResultContainer text={item.name} value={item.price} />
                </GridItem>
              ))}
            </GridContainer>
          </div>
        </div>
        {isZeroCredits && !isPrintMode && (
         <div style={{ marginBottom: "44px" }}><ErrorContainer creditsType="quickview_credits"/></div>
        )}
      </Box>
    </div>
  );
};

export default QuickView;
