import { IAddressData, IAddressMap } from "../types/address.data.interface";
import { URLS } from "../utils/urls";

export const AddressService = {
  async getQuickViewData(
    address: string,
    beds: string,
    bathsValue: string,
    prop: string | null,
    api_key: string
  ) {
    const response = await fetch(`${URLS.QUICK_VIEW}?api_key=${api_key}&address=${address}&bedrooms=${beds}&baths=${bathsValue}&building_type=${prop}`);
    const data = await response.json();
    return data;
  },
  async getAddressDataForMap(
    address: string,
    beds: string,
    bathsValue: string,
    prop: string | null,
    ipAddress: number
  ): Promise<IAddressMap> {
    const response = await fetch(`${URLS.RENTS}?address=${address}&bedrooms=${beds}&bathrooms=${bathsValue}&building_type=${prop}&ip=${ipAddress}`);
    const data: IAddressMap = await response.json();
    return data;
  },

  async getListOfRents(
    address: string,
    beds: string,
    bathsValue: string,
    prop: string | null,
    ipAddress: number
  ): Promise<IAddressData> {
    const response = await fetch(`${URLS.RENTS}/map?address=${address}&bedrooms=${beds}&bathrooms=${bathsValue}&building_type=${prop}&ip=${ipAddress}`);
    const data: IAddressData = await response.json();
    return data;
  },

  async getNearbyComps(
    address: string,
    beds: string,
    bathsValue: string,
    prop: string | null,
    api_key: string
  ) {
    const response = await fetch(`${URLS.NEARBY_COMPS}?api_key=${api_key}&address=${address}&bedrooms=${beds}&baths=${bathsValue}&building_type=${prop}`);
    const data = await response.json();
    return data;
  }
};
