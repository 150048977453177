import React from 'react';

import './Footer.scss';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="main-container footerContainer">
        <ul className="footerLinks">
          <li>
            <a target="_blank" rel="noreferrer" href="https://www.rentometer.com/terms-of-use">
              Terms of Use
            </a>
          </li>
          <li>
            <a target="_blank" rel="noreferrer" href="https://www.rentometer.com/privacy-policy">
              Privacy Policy
            </a>
          </li>
        </ul>
        <span className="copyright">Copyright ©2024 Rentometer, Inc</span>
      </div>
    </footer>
  );
};

export default Footer;
