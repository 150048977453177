import { Route, Router, Routes, useParams } from "react-router-dom";
import EnterpricePage from "./pages/EnterpricePage/EnterpricePage";
import PageWithoutKey from "./pages/PageWithoutKey/PageWithoutKey";
import GenerateKeyPage from "./pages/GenerateKeyPage/GenerateKeyPage";

const App = () => {
  return (
    <Routes>
      <Route path="/:encodedKey" element={<EnterpricePage />} />
      <Route path="/generating-an-encoded-key" element={<GenerateKeyPage />} />

      <Route path="/" element={<EnterpricePage />} />
      <Route path="*" element={<PageWithoutKey />} />

    </Routes>
  );
};

export default App;
