import React from "react";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import styled from "@emotion/styled";

const CustomFormControlLabel = styled(FormControlLabel)`
  && {
    border: 1px solid #b3bcc9;
    border-radius: 7px;
    height: 30px;
    padding: 7px 5px 7px 5px;
    display: flex;
    align-items: center;
    margin: 0;
    gap: 3px;
    flex: 1 1 0; // Allow the element to grow and shrink within the flex container
    min-width: 115px; // Set a min-width to prevent text wrapping

    & .MuiFormControlLabel-label {
      font-size: 14px;
      white-space: nowrap; // Prevent text from wrapping
    }

    ${(props) =>
      props.checked &&
      `
        background-color: #5796D2;
        color: white;

        .MuiRadio-root {
          color: white;
        }
    `}

    ${(props) =>
      props.disabled &&
      `
        border-color: rgba(112, 116, 121, 0.4);
        background-color: rgba(112, 116, 121, 0.05);
        cursor: not-allowed;
        color: #b3bcc9;

        .MuiRadio-root {
          color: ${props.checked ? 'white' : '#b3bcc9'};
        }

        .MuiFormControlLabel-label {
          color: ${props.checked ? 'white' : '#b3bcc9'};
        }
    `}

    ${(props) =>
      props.checked &&
      props.disabled &&
      `
        background-color: #5796D2;
        border-color: none;
        color: white;
  
        .MuiRadio-root {
          color: white;
        }
  
        .MuiFormControlLabel-label {
          color: white;
        }
    `}
  }
`;

const CustomRadio = styled(Radio)`
  && {
    width: 16px;
    height: 16px;

    .MuiSvgIcon-root {
      font-size: 16px;
    }
  }
`;

type Props = {
  value: string;
  selectedValue: string;
  label: string;
  onChange: (event: any) => void;
  minWidth?: string;
  isDisabled: boolean;
};

const CustomRadioElement = ({ value, selectedValue, label, onChange, minWidth, isDisabled }: Props) => (
  <CustomFormControlLabel
    value={value}
    control={<CustomRadio />}
    label={label}
    checked={selectedValue === value}
    disabled={isDisabled}
    onChange={onChange}
    style={{
      minWidth, 
    }}
  />
);

export default CustomRadioElement;
