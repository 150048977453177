import './ResultContainer.scss';

const formatNumberWithCommas = (number: number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const ResultContainer = ({ text, value }: { text: string, value: number }) => {
  return <div className="resultContainer">
    <h6 className="resultText">{text}</h6>
    <h6 className="resultValue">{`$${value >= 1000 ? formatNumberWithCommas(value) : value}`}</h6>
  </div>;
};

export default ResultContainer;
