import "./TitleBox.style.scss";

const TitleBox = () => {
  return (
    <div className="titleBox">
      <h2 className="titleBoxTitle">
        Check rent prices instantly by searching a property below
      </h2>
    </div>
  );
};

export default TitleBox;
