const PageWithoutKey = () => {
  return (
    <>
      <h1>Error: API key is required</h1>
      <p>Please provide a valid API key in the URL.</p>
    </>
  );
};

export default PageWithoutKey;
