import styled from "@emotion/styled";
import { Button, Tooltip } from "@mui/material";
import { isDisabled } from "@testing-library/user-event/dist/utils";

const CustomButton = styled(Button)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  padding: "8px",
  backgroundColor: "#EF6F22",
  borderRadius: "4px",
  color: "white",
  fontSize: "14px",
  fontWeight: "600",
  lineHeight: "25px",
  textTransform: "none",
  height: '45px',
  "&:hover": {
    backgroundColor: "#F28C4E",
  },
  "&.Mui-disabled": {
    backgroundColor: "#F7B790",
    color: "white",
  },
}));

type Props = {
  content: string,
  width: string,
  action: () => Promise<void> | void;
  isDisabled: boolean;
  isZeroCredits: boolean;
  tooltipContent?: string;
}

const ButtonThemeContained = ({ content, width, action, isDisabled, isZeroCredits, tooltipContent }: Props) => {
  return <Tooltip title={isZeroCredits ? tooltipContent : ""}>
    <span>
      <CustomButton sx={{ width }} onClick={action} disabled={isDisabled}>{content}</CustomButton>
    </span>
  </Tooltip>;
};

export default ButtonThemeContained;
