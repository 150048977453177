import { Box } from "@mui/material";
import React from "react";
import Price from "../../../assets/icons/dollar.svg";

interface PropertyRow {
  price: string;
  size: string;
  priceSqft: string;
}

const PriceComponent = ({ row }: { row: PropertyRow }) => {
  const values = [
    row.price !== "-" && `$${Number(row.price).toLocaleString("en-US")}`,
    row.size !== "-" && `s.f.: ${row.size}ft² ${row.priceSqft !== "-" ? `(${row.priceSqft}/ft²)` : ""}`,
    //row.priceSqft !== "-" && `${row.priceSqft}/ft²`,
  ].filter(Boolean);

  return (
    <Box display="flex" alignItems="center" gap="10px">
      {(row.price || row.size || row.priceSqft) && (
        <img src={Price} alt="Price" style={{ alignSelf: 'start' }} />
      )}
      <Box display="flex" alignItems="center" flexWrap={{ xs: 'wrap', sm: 'nowrap' }}>
        <p style={{ margin: 0 }}>Price:</p>
        {values.map((value, index) => (
          <React.Fragment key={index}>
            <p style={{ margin: 0, marginLeft: index === 0 ? '8px' : '0' }}>{value}</p>
            {index < values.length - 1 && (
              <span style={{ margin: '0 8px' }}>|</span>
            )}
          </React.Fragment>
        ))}
      </Box>
    </Box>
  );
};

export default PriceComponent;
