import React, { useState, useEffect } from 'react';
import './Tabs.scss';

import { ReactComponent as InputTab } from '../../assets/icons/inputTab.svg';
import { ReactComponent as MeterTab } from '../../assets/icons/meterTab.svg';
import { ReactComponent as ListingTab } from '../../assets/icons/listingTab.svg';
import { Link } from 'react-scroll';

interface TabsProps {
  sectionRefs: {
    section1: React.RefObject<HTMLDivElement>;
    section2: React.RefObject<HTMLDivElement>;
    section3: React.RefObject<HTMLDivElement>;
  };
  isShowQuickView: boolean;
  showRentList: boolean;
}

const Tabs: React.FC<TabsProps> = ({ sectionRefs, isShowQuickView, showRentList}) => {
  const [activeSection, setActiveSection] = useState<string>('section1');

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '-50% 0px -50% 0px', // Враховуємо половину видимості
      threshold: 0,
    };

    const callback: IntersectionObserverCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);

    // Додаємо всі елементи секцій до спостереження
    Object.values(sectionRefs).forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      // Відміняємо спостереження при видаленні компонента
      observer.disconnect();
    };
  }, []);

  return (
    <div className="tabContainer">
      <nav className="container tabItems">
        <Link
          to="section1"
          spy={true}
          smooth={true}
          duration={300}
          offset={-100}
          className={`tabLabel ${activeSection === 'section1' ? 'tabActive' : ''}`}
        >
          <InputTab />
          Inputs
        </Link>
        <Link
          to="section2"
          spy={true}
          smooth={true}
          duration={300}
          offset={-70}
          className={`tabLabel ${activeSection === 'section2' ? 'tabActive' : ''}`}
          style={{ opacity: isShowQuickView ? 1 : 0.5, pointerEvents: isShowQuickView ? 'auto' : 'none' }}
        >
          <MeterTab />
          Result
        </Link>
        <Link
          to="section3"
          spy={true}
          smooth={true}
          duration={300}
          className={`tabLabel ${activeSection === 'section3' ? 'tabActive' : ''}`}
          style={{ opacity: showRentList ? 1 : 0.5, pointerEvents: isShowQuickView ? 'auto' : 'none' }}
        >
          <ListingTab />
          Details
        </Link>
      </nav>
    </div>
  );
};

export default Tabs;
