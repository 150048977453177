import { URLS } from "../utils/urls";

export const UserService = {
  async getIPAddress() {
    return (await fetch(URLS.GET_IP_URL)).json();
  },

  async getUserAuthInfo(API_KEY: string) {
    return (await fetch(`${URLS.USER_AUTH}?api_key=${API_KEY}`)).json()
  } 
};
