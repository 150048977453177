const RentMeterSVG = () => {
  return (
    <svg
      width="245"
      height="158"
      viewBox="0 0 245 158"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 122.38C0 54.7915 54.8451 0 122.5 0C190.155 0 245 54.7915 245 122.38C245 134.484 243.241 146.178 239.964 157.221H5.03557C1.759 146.178 0 134.484 0 122.38Z"
        fill="#D5D9E0"
      />
      <path
        d="M5.28125 124.036C5.28125 59.0902 57.7646 6.44141 122.506 6.44141C187.248 6.44141 239.731 59.0902 239.731 124.036C239.731 135.667 238.048 146.903 234.912 157.514H10.1C6.9645 146.903 5.28125 135.667 5.28125 124.036Z"
        fill="white"
      />
      <path
        d="M15.315 151.606C11.2491 135.236 10.9189 118.148 14.3492 101.631C17.7796 85.1133 24.881 69.597 35.1183 56.2515L48.2255 66.4888C39.5239 77.8325 33.4876 91.0213 30.5718 105.061C27.656 119.101 27.9367 133.626 31.3927 147.54L15.315 151.606Z"
        fill="#F6DAC8"
      />
      <path
        d="M229.314 153.057C233.733 136.23 234.185 118.592 230.635 101.556C227.085 84.5206 219.631 68.5604 208.871 54.9536L195.915 65.3856C205.062 76.9514 211.397 90.5175 214.415 104.998C217.432 119.478 217.048 134.47 213.292 148.773L229.314 153.057Z"
        fill="#CCE0C5"
      />
      <path
        d="M15.9983 152.141L31.4531 148"
        stroke="#717780"
        strokeWidth="2"
        stroke-linecap="round"
      />
      <path
        d="M25 132H17"
        stroke="#919191"
        strokeWidth="2"
        stroke-linecap="round"
      />
      <path
        d="M24.8785 114.389L17 113"
        stroke="#919191"
        strokeWidth="2"
        stroke-linecap="round"
      />
      <path
        d="M28.6006 96.4995L20.9922 94.0273"
        stroke="#919191"
        strokeWidth="2"
        stroke-linecap="round"
      />
      <path
        d="M34.9282 81L28 77"
        stroke="#919191"
        strokeWidth="2"
        stroke-linecap="round"
      />
      <path
        d="M47.966 66.0407L36 56"
        stroke="#717780"
        strokeWidth="2"
        stroke-linecap="round"
      />
      <path
        d="M71.5 40.5263L66 31"
        stroke="#919191"
        strokeWidth="2"
        stroke-linecap="round"
      />
      <path
        d="M103.91 27.8329L102 17"
        stroke="#919191"
        strokeWidth="2"
        stroke-linecap="round"
      />
      <path
        d="M139.996 27.8329L141.906 17"
        stroke="#919191"
        strokeWidth="2"
        stroke-linecap="round"
      />
      <path
        d="M173 40.5263L178.5 31"
        stroke="#919191"
        strokeWidth="2"
        stroke-linecap="round"
      />
      <path
        d="M196.494 65.4984L208.494 55.4965"
        stroke="#717780"
        strokeWidth="2"
        stroke-linecap="round"
      />
      <path
        d="M211.072 81L218 77"
        stroke="#919191"
        strokeWidth="2"
        stroke-linecap="round"
      />
      <path
        d="M217.001 96.4721L224.609 94"
        stroke="#919191"
        strokeWidth="2"
        stroke-linecap="round"
      />
      <path
        d="M220.122 114.389L228 113"
        stroke="#919191"
        strokeWidth="2"
        stroke-linecap="round"
      />
      <path
        d="M221 132H229"
        stroke="#919191"
        strokeWidth="2"
        stroke-linecap="round"
      />
      <path
        d="M214.002 147.998L229.438 152.211"
        stroke="#717780"
        strokeWidth="2"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default RentMeterSVG;