export const columns = [
  { id: 'address', label: 'Address' },
  { id: 'distance', label: 'Distance' },
  { id: 'price', label: 'Rent' },
  { id: 'size', label: 'Size' },
  { id: 'priceSqft', label: '$/ft²' },
  { id: 'bedrooms', label: 'Beds' },
  { id: 'bathrooms', label: 'Baths' },
  { id: 'propertyType', label: 'Bldg Type' },
  { id: 'listDate', label: 'Last Seen' },
  // { id: 'longitude', label: 'Longitude'},
  // { id: 'latitude', label: 'Latitude'},
];

export const columnsSpecial = [
  { id: 'Property Name', label: 'Property Name' },
  { id: 'Formatted Address', label: 'Address' },
  { id: 'date_collected', label: 'Verified On' },
  { id: 'Phone Number', label: 'Phone Number' },
  { id: 'Web Address', label: 'Web Address' },
  { id: 'Contact Us URL', label: 'Contact Us' }
];
