import { useEffect, useRef } from 'react';
import logoImage from '../../assets/image 2 copy.svg';
import './Header.scss';
import LogoHeaderComponent from '../../pages/EnterpricePage/components/LogoHeaderComponent';

const Header = () => {
  const navLinksRef = useRef<HTMLUListElement | null>(null);
  const burgerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleBurgerClick = () => {
      if (navLinksRef.current && burgerRef.current) {
        navLinksRef.current.classList.toggle('active');
        burgerRef.current.classList.toggle('active');
      }
    };

    if (burgerRef.current) {
      burgerRef.current.addEventListener('click', handleBurgerClick);
    }

    return () => {
      if (burgerRef.current) {
        burgerRef.current.removeEventListener('click', handleBurgerClick);
      }
    };
  }, []);

  return (
    <header className="header">
      <div className="main-container headerContainer none">
        <div className="logo">
          {/* <img src={logo} alt="Logo" /> */}
          {/* <img src={logoImage} alt="Logo" height={26} width={107}/> */}
          <LogoHeaderComponent />
        </div>
        <nav className="nav">
          <ul className="nav-links" ref={navLinksRef}>
            <li className="nav-button">
              <a href="https://www.rentometer.com/feedback/new" target='_blank'>Contact Us</a>
            </li>
          </ul>
          <div className="burger" ref={burgerRef}>
            <div className="line1"></div>
            <div className="line2"></div>
            <div className="line3"></div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
