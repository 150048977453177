import { Button, TextField, Box, Typography, Snackbar, Alert } from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";

const GenerateKeyPage = () => {
  const [key, setKey] = useState('');
  const [encodedKey, setEncodedKey] = useState('');
  const [copySuccess, setCopySuccess] = useState(false);

  const handleEncodeKey = () => {
    setEncodedKey(btoa(key));
  }

  const handleCopyToClipboard = () => {
    const fullUrl = `${window.location.origin}/${encodedKey}`;
    navigator.clipboard.writeText(fullUrl).then(() => {
      setCopySuccess(true);
    }, (err) => {
      console.error('Failed to copy: ', err);
    });
  }

  const handleCloseSnackbar = () => {
    setCopySuccess(false);
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 2,
        gap: 2,
        maxWidth: 700,
        margin: 'auto',
        marginTop: 4,
        boxShadow: 3,
        borderRadius: 2,
      }}
    >
      <Typography variant="h6" gutterBottom>
        Generate Encoded API Key
      </Typography>
      <TextField 
        label="Enter API Key"
        variant="outlined"
        fullWidth
        onChange={(e) => setKey(e.target.value)}
      />
      <Button 
        variant="contained" 
        color="primary" 
        onClick={handleEncodeKey}
        fullWidth
      >
        Generate link with encoded API key
      </Button>
      {encodedKey && 
        <>
          <Typography variant="body1" gutterBottom>
            <Link to={`/${encodedKey}`} style={{ textDecoration: 'none', color: 'blue' }}>
              {`${window.location.origin}/${encodedKey}`}
            </Link>
          </Typography>
          <Button 
            variant="outlined" 
            color="secondary" 
            onClick={handleCopyToClipboard}
          >
            Copy to Clipboard
          </Button>
        </>
      }
      <Snackbar 
        open={copySuccess} 
        autoHideDuration={3000} 
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          URL copied to clipboard!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default GenerateKeyPage;
