import React from "react";
import { Box } from "@mui/material";
import PriceComponent from "./PriceComponent";
import { PropertyRow } from "./ListingTable.component";
import Build_location from "../../../assets/icons/zondicons_location.svg";
import Apartment from "../../../assets/icons/ic_round-apartment.svg";
import House from "../../../assets/icons/mdi_house.svg";

const MobileRentView = ({
  paginatedRows,
}: {
  paginatedRows: PropertyRow[];
}) => {
  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "short",
    };
    return date.toLocaleDateString("en-US", options);
  };

  return (
    paginatedRows && (
      <React.Fragment>
        <Box
          sx={{
            backgroundColor: "#F3F6F9",
            padding: "16px 10px",
            fontSize: "16px",
            fontWeight: 500,
            marginBottom: "10px",
          }}
        >
          Results
        </Box>
        {paginatedRows.map((row, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              padding: "10px",
              backgroundColor: index % 2 !== 0 ? "#F3F6F9" : "white",
              borderBottom: "1px solid #D2DBE8"
            }}
          > 
            <Box display="flex" alignItems="start" gap="10px">
              {row.address && <img src={Build_location} alt="Build_location" />}
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: 400,
                  lineHeight: "24px",
                  padding: 0,
                  margin: 0,
                  height: "min-content",
                }}
              >
                {`${row.address} (${row.distance})`}
              </p>
            </Box>

            <Box display="flex" alignItems="start" gap="10px">
              {(row.propertyType === "House" ||
                row.propertyType === "Duplex") && (
                <img src={House} alt="building type" />
              )}
              {(row.propertyType === "Apartment" ||
                row.propertyType === "Condo" ||
                row.propertyType === "Townhouse") && (
                <img src={Apartment} alt="building type" />
              )}
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: 400,
                  lineHeight: "24px",
                  padding: 0,
                  margin: 0,
                  height: "min-content",
                }}
              >{`${row.propertyType} - ${row.bedrooms}BR/${
                row.bathrooms
              }ba (${formatDate(row.listDate)})`}</p>
            </Box>

            <PriceComponent row={row} />
          </Box>
        ))}
      </React.Fragment>
    )
  );
};

export default MobileRentView;
