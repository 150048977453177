import { Box, Typography } from "@mui/material";
import creditsAlertIcon from "../../../assets/icons/mingcute_alert-fill.svg";
import useMediaQuery from "../../../hooks/useMediaQuery";

import './ErrorContainer.scss';

const ErrorContainer = ({
  creditsType,
}: {
  creditsType: "premium_credits" | "quickview_credits";
}) => {
  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <>
      <Box className="creditsAlert">
        <img src={creditsAlertIcon} alt="" />

        <div
          style={{
            display: "flex",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          {creditsType === "premium_credits" ? (
            <h5
              className="creditsAlertText"
            >
              Your QuickView™ Rent Estimate credit balance is 0. Please contact{" "}
                <a
                  className="creditsAlertEmail"
                  href="https://www.rentometer.com/feedback/new"
                  target="_blank"
                >
                  support@rentometer.com
                </a>{" "}
              for assistance.
            </h5>
          ) : (
            <h5
              className="creditsAlertText"
            >
              Your Rent Comp credit balance is 0. Please contact{" "}
              <a
                className="creditsAlertEmail"
                href="https://www.rentometer.com/feedback/new"
                target="_blank"
              >
                support@rentometer.com
              </a>{" "}
              for assistance.
            </h5>
          )}
        </div>
      </Box>
    </>
  );
};

export default ErrorContainer;
