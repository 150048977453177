import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import RentometerLogo from "../../../components/RentometerLogo/RentometetLogo";
import { Typography } from "@mui/material";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    border: '1px solid none',
    borderRadius: "10px",
    boxShadow: 24,
    p: "40px 24px",
  };

const ApiRequiredModal = ({ errorMessage }: { errorMessage: string }) => {
  const [open, setOpen] = React.useState(true);
  const handleOpen = () => setOpen(true);
  //const handleClose = () => setOpen(false);

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={open}
        //onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {/* <Box sx={style}>
            <div style={{ display: "flex", flexDirection: "column", gap: "40px", alignItems: "center" }}>
            <RentometerLogo />
            <h4 style={{ fontSize: "20px" }}>{errorMessage}</h4>

            </div>
        </Box> */}
        <Box sx={style}>
          <Typography style={{
            fontSize: "24px",
            fontWeight: 600,
            lineHeight: "34px",
            textAlign: "center",
            color: "#2D3035"
          }}>{errorMessage}</Typography>

          <Typography style={{
            fontSize: "18px",
            fontWeight: 400,
            lineHeight: "32px",
            textAlign: "center",
            color: "#2D3035"
          }}>To use this tool, you need to enter an API key</Typography>

        <label htmlFor="api-key" className="formRequestLabel">
          API key
          <input
            type="text"
            className="formRequestInput"
            id="address"
            //ref={inputRef}
            disabled={false}
          />
          <Button
            disabled={true}
            variant="contained"
            style={{
              height: "50px"
            }}
            >
            {/* <CompasSVG /> */}
            Confirm
          </Button>
        </label>
        </Box>
      </Modal>
    </div>
  );
};

export default ApiRequiredModal;
