import { useState, useEffect } from 'react';

const useBrowserDetection = () => {
  const [browser, setBrowser] = useState('other');

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();

    if (userAgent.includes('chrome') && !userAgent.includes('edge') && !userAgent.includes('opr')) {
      setBrowser('chrome');
    } else if (userAgent.includes('safari') && !userAgent.includes('chrome')) {
      setBrowser('safari');
    } else {
      setBrowser('other');
    }
  }, []);

  return browser;
};

export default useBrowserDetection;
