import { useEffect, useMemo, useState } from "react";
import { AddressService } from "../../../../services/address.service";
import {
  IAddressData,
  IAddressMap,
} from "../../../../types/address.data.interface";
import { UserService } from "../../../../services/user.service";
import CustomMap from "../../components/Map";
import ListingTable from "../../components/ListingTable.component";

export type MarkerPosition = {
  position: { lat: number; lng: number };
  address: string;
  baths: string;
  bedrooms: number;
  price: string;
  property_type: string;
};

const RentComps = ({
  rentCompsData,
  rentCompsDataMap,
  rentCompsRef,
}: {
  rentCompsData: IAddressData;
  rentCompsDataMap: IAddressMap;
  rentCompsRef: React.RefObject<HTMLDivElement>;
}) => {
  //mao sttate
  const [tableType, setTableType] = useState<"cheapest" | "closest">(
    "cheapest"
  );

  const [panorama, setPanorama]: any = useState(false);

  const memoizedMapValues = useMemo(() => {
    let centerPosition;
    const markerPositionsGreen: MarkerPosition[] = [];
    if (rentCompsData !== null && Object.keys(rentCompsData).length !== 0) {
      centerPosition = {
        lat: parseFloat(rentCompsData.search_latitude),
        lng: parseFloat(rentCompsData.search_longitude),
      };

      rentCompsData.nearby_properties &&
        rentCompsData.nearby_properties.forEach((item) => {
          const {
            latitude,
            longitude,
            address,
            baths,
            bedrooms,
            price,
            property_type,
          } = item;
          const position = { lat: latitude, lng: longitude };
          const formattedPrice = price
            ? "$" + price.toLocaleString().split(/\s/).join(",")
            : "-";
          const marker = {
            position,
            address,
            baths,
            bedrooms,
            price: formattedPrice,
            property_type,
          };
          markerPositionsGreen.push(marker);
        });
    }
    return {
      centerPosition,
      markerPositionsGreen,
    };
  }, [rentCompsData]);

  const { centerPosition, markerPositionsGreen } = memoizedMapValues;

  const nearby_comps = rentCompsData.nearby_properties.map((el) => ({
    ...el,
    sqft: el.sqft ? el.sqft : 0,
    dollar_sqft: el.dollar_sqft ? el.dollar_sqft: 0
  }));

  return (
    <div ref={rentCompsRef}>
      <div style={{ marginBottom: "40px" }} className="none">
        {rentCompsDataMap !== null && (
          <CustomMap
            centerPosition={centerPosition}
            markerPositionsGreen={markerPositionsGreen}
            markerPositionsBlue={[]}
            radius={rentCompsDataMap.radius_miles}
            panorama={panorama}
            setPanorama={setPanorama}
            avarege={rentCompsDataMap.mean}
            median={rentCompsDataMap.median}
            samples={rentCompsDataMap.samples}
            specialData={[]}
            tableType={tableType}
          />
        )}
      </div>

      {rentCompsData !== null && (
        <ListingTable
          data={rentCompsData}
          initialDataCheapest={nearby_comps}
          setPanorama={setPanorama}
          panorama={panorama}
          handleTableTypeChange={() => {}}
          tableType={tableType}
          totalPay={500}
          avarege={rentCompsDataMap?.mean}
          proptypeOrigin={null}
          specialData={[]}
          credits={rentCompsDataMap.credits_remaining}
        />
      )}
    </div>
  );
};

export default RentComps;
